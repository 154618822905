
$color : ("black", "cyan-bluish-gray","white", "pale-pink","vivid-red","luminous-vivid-orange", "luminous-vivid-amber","light-green-cyan","vivid-green-cyan",
"pale-cyan-blue","vivid-cyan-blue","vivid-purple","contrast","dark","secondary", "magenta", "primary", "tertiary", "base", "custom-rosso","custom-arancio","base","base2",
"contrast","contrast-2","contrast-3","accent","accent-2","accent-3","accent-4","accent-5", "custom-gray", "custom-teal", "custom-beige", "custom-light-grey", "custom-light-blue");

$font :("small","medium","large","x-large","xx-large");

@mixin setColorBorderBg($color){
	@each $i in $color{
		.has-#{$i} {
            &-background-color{
                background-color: var(--wp--preset--color--#{$i}) !important;
            }
            &-color{
                color: var(--wp--preset--color--#{$i}) !important;
            }
            &-border-color{
                color: var(--wp--preset--color--#{$i}) !important;
            }
        }

        
	}
}

@mixin setColorWpBlockQuote($color){
    .wp-block-quote {
        border-left: 7px solid;
        border-color: var(--wp--preset--color--custom-arancio);
        @each $i in $color{
            @if ($i != "primary") {
                &.#{$i} {
                    border-color: var(--wp--preset--color--#{$i});
                }
            }        
        }
    }
}

@mixin setFont($font){
    @each $i in $font{
		.has-#{$i}-font-size{
            font-size: var(--wp--preset--font-size--#{$i});
        }
	}
}

@include setColorBorderBg($color);
@include setColorWpBlockQuote($color);
@include setFont($font);