@use "sass:math";
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "wordPress/index.scss";
@import "print.scss";


/*HTML*/
.overflow-hidden-html {
  overflow: hidden;
}

/*FOOTER*/
.footer-contact-div {
  background: linear-gradient(
    90deg,
    rgb(12, 169, 197) 0%,
    rgb(18, 197, 216) 99%
  );
}

.footer-contact-div h4,
.footer-social-div p {
  background-image: linear-gradient(
    45deg,
    rgb(118, 38, 120) 16.666%,
    rgb(218, 31, 124) 83.333%
  );
  -webkit-text-fill-color: transparent;
  filter: drop-shadow(2px 2px #fff);
}
.calc-padding-left {
  padding-left: calc((100% - 1360px - 3rem) / 1.66);
}
/*SCROLL PAGE*/
::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #b3b3b3;
  border-radius: 5px;
  background: #e6e6e6;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f49332;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f49332;
}

img {
  pointer-events: none;
}

/*PAGE NUMERI PRECEDENTI*/
a.numeri-precedenti-link-number:hover span.numeri-precedenti-span-description {
  color: #f49332;
}

.content-articleWrap::after {
  content: "";
  flex: auto;
}

.articleWrap {
  flex-basis: calc(25% - 24px);
}

.numeri-precedenti-bg-Orange {
  background-color: #eb6735;
}

.numeri-precedenti-bg-Yellow {
  background-color: #ceba57;
}

.numeri-precedenti-bg-Light-Green {
  background-color: #83b769;
}

.numeri-precedenti-bg-Dark-Green {
  background-color: #53a685;
}

.numeri-precedenti-bg-Light-Blue {
  background-color: #51a4ba;
}

.numeri-precedenti-bg-Dark-Blue {
  background-color: #4f5ca6;
}

.numeri-precedenti-bg-Purle {
  background-color: #9e569f;
}

.numeri-precedenti-bg-Borgona {
  background-color: #bd5466;
}

/*Search style*/

mark {
  background-color: #f3e8ca;
  border-radius: 17px;
  padding: 0 6px;
}

.input-container {
  position: relative;
}

.input-container .input-search-icon {
  position: absolute;
  left: 12px;
  top: 12px;
  content: url("/icon/ico-search-lightBlue.svg");
  width: 20px;
  height: 20px;
}

.input-ico-sort {
  left: 12px;
  top: 10px;
  content: url("/icon/ico-sort.svg");
  width: 15px;
  height: 15px;
}

.form-control {
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
}

.form-control + .form-control {
  margin-top: 1em;
}

input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
  color: currentColor;
  width: 15px;
  height: 15px;
  border: 1px solid #f49332;
  border-radius: 4px;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 12px;
  height: 10px;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #f49332;
}

input[type="checkbox"]:checked::before {
  transform: scale(1.5);
}

input[type="radio"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
  color: currentColor;
  width: 16px;
  height: 16px;
  border: 1px solid #f49332;
  border-radius: 20px;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

input[type="radio"]::before {
  appearance: none;
  width: 14px;
  height: 14px;
  border-radius: 10px;
  content: "";
  border:1px solid white;
}

input[type="radio"]:checked::before {
  background-color: #f49332;
}

.rmsc {
  .dropdown-heading-dropdown-arrow, .dropdown-search-clear-icon{
    color: rgb(61, 68, 77) !important;
  }
  .dropdown-container{
    border-color: rgb(61, 68, 77) !important;
    border-radius: 0 !important;
    &:focus-within{
      box-shadow: #000 0 0 1px !important;
      border:2px solid #000 !important ;
    }
  }
}

.input {
  /* Reset. */
  border: unset;
  
  /* Style */
  width: 100% !important;
  padding: 0.75rem;
  border: 1px solid rgba(0, 0, 0, 0.1);

  /* Arrow */
  appearance: none;
  background-image: url("/icon/ico-select.svg");
  background-repeat: no-repeat;
  background-position: right 0.7rem top 50%;
  background-size: 0.90rem auto;
}

@keyframes rainbow-text-simple-animation-rev {
  0% {
    background-size: 650%;
  }
  40% {
    background-size: 650%;
  }
  100% {
    background-size: 100%;
  }
}
@keyframes rainbow-text-simple-animation {
  0% {
    background-size: 100%;
  }
  80% {
    background-size: 650%;
  }
  100% {
    background-size: 650%;
  }
}
@keyframes marquee {
  from { transform: translateX(0); }
  to { transform: translateX(-50%); }
}
.soundcloud-player {
  background: #e5e6e7;
  padding: 15px 15px 10px;
  border-radius: 15px;
}

/*
* carousel
**/
.feat-carousel .alice-carousel__stage {
  display: flex
}
  
.feat-carousel .alice-carousel__stage-item {
  flex: 1 0 auto;
  height: auto;
}

.my-masonry-grid {
  display: flex;
}
 
@media screen and (min-width:1470px){
  .numeroCorrenteMain{
    padding-left: calc((100% - 1470px + 2rem) / 2);
  }
}

@media screen and (max-width: 1023px) {
  .footer-contact-div h4 {
    filter: drop-shadow(2px 2px #fff);
  }
}

@media screen and (min-width: 1023px) {
  .article .articleWrap:nth-child(3n + 0) {
    border-right-width: 0px;
  }
}

@media screen and (min-width: 768px) {
  .header-btn-newsletter a {
    background: linear-gradient(
      90deg,
      rgb(147, 39, 143) 0%,
      rgb(237, 30, 121) 100%
    );
  }

  .vimeo-flex {
    height: 55px;
    overflow-y: hidden;
    position: relative;
    margin-bottom: 30px;

    iframe {
      position: absolute;
      left: 0;
      bottom: 0;
      max-width: 100%;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .section-ultimi-articoli .articleWrap:not(:first-child) figure {
    display: none;
  }

  .articleWrap:nth-child(2n + 0) {
    border-right-width: 0px;
  }

}

@media screen and (max-width: 767px) {
  .vimeo-flex iframe {
    max-width: 100%;
    aspect-ratio: 16/9;
    max-height: 150px;
  }
}
