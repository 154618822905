.is-layout-constrained * + * {
  margin-block-start: 1.5rem;
  margin-block-end: 0;
}
@supports (-moz-appearance:none) {
  .is-layout-constrained * + *, .is-layout-constrained p  {
    margin-block-start:0;
  }
}
.is-layout-constrained p {
  margin-block-start: 1.5rem;
  margin-block-end: 0;
}

.is-layout-constrained ul {
  list-style-type: disc;
}

.is-layout-constrained h5 {
  text-transform: uppercase;
  font-weight: 700;
}
.is-layout-constrained h6 {
  text-transform: uppercase;
}

.is-layout-constrained h4 {
  font-weight: 400;
  line-height: 1.4;
  font-size: clamp(1.75rem, 1.75rem + ((1vw - 0.2rem) * 0.204), 1.875rem);
}

.is-layout-constrained .alignleft {
  float: left;
  margin-inline-start: 0;
  margin-inline-end: 2em;
}

.et-box {
  margin: 20px 0 10px 0;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .1);
  box-shadow: 0 1px 1px rgba(0, 0, 0, .1);
  font-size: 14px;
  position: relative;
}

.et-shadow::before {
  content: "";
  position: absolute;
  top: 32px;
  left: 28px;
}

.et-shadow {
  border: 1px solid #ddd;
}

.et-bio {
  border: 1px solid #b3d8d3;
  .et-box-content {
    background: #ddf5f7;
    border: 1px solid #f1fafb;
    -webkit-box-shadow: inset 0 0 45px #c6edf0;
    box-shadow: inset 0 0 45px #c6edf0;
}
}

article .et-box.et-shadow {
  margin-bottom: 30px;
}

.et-box-content {
  text-shadow: 1px 1px 1px #ffffff;
  padding: 27px 43px 17px 66px;
  min-height: 35px;
  p+br {
    display: none;
  }
}

.et-shadow .et-box-content {
  color: #737373;
  background: #fafafa;
  border: 1px solid #fafafa;
  -webkit-box-shadow: inset 0 0 45px #e7e7e7;
  box-shadow: inset 0 0 45px #e7e7e7;
}

.post_content {
  a[rel~=noreferrer],a[rel~=noreferrer]:hover, a[rel~=noreferrer]:focus{
    color: var(--wp--preset--color--primary);
  }

  ul{
    padding-left: 40px;
  }
  li{
    margin-block-start: 0px;
  }
  .wp-block-separator {
    border-width: 1px;
    border-style: solid;
  }

  .wp-block-buttons {
    gap: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .aligncenter {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .wp-block-image {
    &.aligncenter {
      text-align: center;
    }
  }

  .wp-element-button,
  .wp-block-button__link {
    background-color: var(--wp--preset--color--primary);
    border-radius: 0;
    border-width: 0;
    color: var(--wp--preset--color--contrast);
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    padding: calc(0.667em + 2px) calc(1.333em + 2px);
    text-decoration: none;
  }

  .wp-element-button {
    &:hover {
      background-color: var(--wp--preset--color--contrast);
    }
  }

  .is-layout-constrained {
    a {
      &:hover {
        text-decoration: underline;
        opacity: 0.85;
      }
    }
  }

  .wp-block-gallery {
    display: flex;
    flex-wrap: wrap;
    align-items: normal;
    gap: 0.5em;
  }

  .wp-block-file {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid grey;
    flex-wrap: nowrap;
    a {
      font-size: 16px;
      font-weight: 600;
      color: var(--wp--preset--color--contrast);
    }
    .wp-element-button,
    .wp-block-button__link {
      color: var(--wp--preset--color--base) !important;
      border-radius: 50px;
      padding: 10px 15px;
      background-color: var(--wp--preset--color--primary);
    }

    .wp-element-button {
      &:hover {
        background-color: var(--wp--preset--color--contrast);
      }
    }
  }

  .wp-site-blocks {
    padding-top: var(--wp--style--root--padding-top);
    padding-bottom: var(--wp--style--root--padding-bottom);
  }
  .has-global-padding {
    padding-right: var(--wp--style--root--padding-right);
    padding-left: var(--wp--style--root--padding-left);
  }
  .has-global-padding :where(.has-global-padding:not(.wp-block-block)) {
    padding-right: 0;
    padding-left: 0;
  }
  .has-global-padding > .alignfull {
    margin-right: calc(var(--wp--style--root--padding-right) * -1);
    margin-left: calc(var(--wp--style--root--padding-left) * -1);
  }
  .has-global-padding
    :where(.has-global-padding:not(.wp-block-block))
    > .alignfull {
    margin-right: 0;
    margin-left: 0;
  }
  .has-global-padding
    > .alignfull:where(
      :not(.has-global-padding):not(.is-layout-flex):not(.is-layout-grid)
    )
    > :where(
      [class*="wp-block-"]:not(.alignfull):not([class*="__"]),
      p,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      ul,
      ol
    ) {
    padding-right: var(--wp--style--root--padding-right);
    padding-left: var(--wp--style--root--padding-left);
  }
  .has-global-padding
    :where(.has-global-padding)
    > .alignfull:where(:not(.has-global-padding))
    > :where(
      [class*="wp-block-"]:not(.alignfull):not([class*="__"]),
      p,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      ul,
      ol
    ) {
    padding-right: 0;
    padding-left: 0;
  }
  .wp-site-blocks > .alignleft {
    float: left;
    margin-right: 2em;
  }
  .wp-site-blocks > .alignright {
    float: right;
    margin-left: 2em;
  }
  .wp-site-blocks > .aligncenter {
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
  .is-layout-flex {
    display: flex;
  }
  .is-layout-flex {
    flex-wrap: wrap;
    align-items: center;
  }
  .is-layout-flex > * {
    margin: 0;
  }

  .wp-block-media-text{
    +.has-media-on-the-right{
      grid-template-columns: 1fr 50%;
      .wp-block-media-text__content {
        grid-column: 1;
        grid-row: 1;
      }

      .wp-block-media-text__media {
        grid-column: 2;
        grid-row: 1;
    }
    }
  }
  :where(.wp-site-blocks) > * {
    margin-block-start: 1.5rem;
    margin-block-end: 0;
  }
  :where(.wp-site-blocks) > :first-child:first-child {
    margin-block-start: 0;
  }
  :where(.wp-site-blocks) > :last-child:last-child {
    margin-block-end: 0;
  }
  body {
    --wp--style--block-gap: 1.5rem;
  }
  :where(body .is-layout-flow) > :first-child:first-child {
    margin-block-start: 0;
  }
  :where(body .is-layout-flow) > :last-child:last-child {
    margin-block-end: 0;
  }
  :where(body .is-layout-flow) > * {
    margin-block-start: 1.5rem;
    margin-block-end: 0;
  }
  :where(body .is-layout-constrained) > :first-child:first-child {
    margin-block-start: 0;
  }
  :where(body .is-layout-constrained) > :last-child:last-child {
    margin-block-end: 0;
  }
  :where(body .is-layout-constrained) > * {
    margin-block-start: 1.5rem;
    margin-block-end: 0;
  }
  :where(body .is-layout-flex) {
    gap: 1.5rem;
  }
  :where(body .is-layout-grid) {
    gap: 1.5rem;
  }
  body .is-layout-flow > .alignleft {
    float: left;
    margin-inline-start: 0;
    margin-inline-end: 2em;
  }
  body .is-layout-flow > .alignright {
    float: right;
    margin-inline-start: 2em;
    margin-inline-end: 0;
  }
  body .is-layout-flow > .aligncenter {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  body .is-layout-constrained > .alignleft {
    float: left;
    margin-inline-start: 0;
    margin-inline-end: 2em;
  }
  body .is-layout-constrained > .alignright {
    float: right;
    margin-inline-start: 2em;
    margin-inline-end: 0;
  }
  body .is-layout-constrained > .aligncenter {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  body
    .is-layout-constrained
    > :where(:not(.alignleft):not(.alignright):not(.alignfull)) {
    max-width: var(--wp--style--global--content-size);
    margin-left: auto !important;
    margin-right: auto !important;
  }
  body .is-layout-constrained > .alignwide {
    max-width: var(--wp--style--global--wide-size);
  }
  body .is-layout-grid {
    display: grid;
  }
  body .is-layout-grid > * {
    margin: 0;
  }
  body {
    background-color: var(--wp--preset--color--base);
    color: var(--wp--preset--color--contrast);
    font-family: var(--wp--preset--font-family--bitter);
    font-size: var(--wp--preset--font-size--small);
    line-height: 1.6;
    --wp--style--root--padding-top: var(--wp--preset--spacing--40);
    --wp--style--root--padding-right: var(--wp--preset--spacing--30);
    --wp--style--root--padding-bottom: var(--wp--preset--spacing--40);
    --wp--style--root--padding-left: var(--wp--preset--spacing--30);
  }
  a:where(:not(.wp-element-button)) {
    font-weight: 500;
  }
  a:where(:not(.wp-element-button)):hover {
    text-decoration: underline;
  }
  a:where(:not(.wp-element-button)):focus {
    text-decoration: underline solid;
  }
  a:where(:not(.wp-element-button)):active {
    color: var(--wp--preset--color--secondary);
    text-decoration: none;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.4;
  }
  h1 {
    font-size: clamp(2.032rem, 2.032rem + ((1vw - 0.2rem) * 2.601), 3.625rem);
    line-height: 1.2;
  }
  h2 {
    font-size: clamp(
      1.9rem,
      calc(1.9rem + ((1vw - 0.48rem) * 6.4135)),
      2.6rem
    );
    line-height: 1.2;
  }
  h3 {
    font-size: var(--wp--preset--font-size--x-large);
  }
  h4 {
    font-size: var(--wp--preset--font-size--large);
  }
  h5 {
    font-size: var(--wp--preset--font-size--medium);
    text-transform: uppercase;
  }
  h6 {
    font-size: var(--wp--preset--font-size--medium);
    text-transform: uppercase;
  }
  .wp-element-button,
  .wp-block-button__link {
    background-color: var(--wp--preset--color--primary);
    border-radius: 0;
    border-width: 0;
    color: var(--wp--preset--color--contrast);
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    padding: calc(0.667em + 2px) calc(1.333em + 2px);
    text-decoration: none;
  }
  .wp-element-button:visited,
  .wp-block-button__link:visited {
    color: var(--wp--preset--color--contrast);
  }
  .wp-element-button:hover,
  .wp-block-button__link:hover {
    background-color: var(--wp--preset--color--contrast);
    color: var(--wp--preset--color--base);
  }
  .wp-element-button:focus,
  .wp-block-button__link:focus {
    background-color: var(--wp--preset--color--contrast);
    color: var(--wp--preset--color--base);
  }
  .wp-element-button:active,
  .wp-block-button__link:active {
    background-color: var(--wp--preset--color--secondary);
    color: var(--wp--preset--color--base);
  }
  .wp-block-separator:not(.is-style-wide):not(.is-style-dots):not(
      .alignwide
    ):not(.alignfull) {
    width: 100px;
  }

  .wp-block-social-links {
    gap: 1.5rem;
    flex-wrap: wrap;
    align-items: center;
    padding-left: 0;
    .wp-block-social-link-anchor{
      &:hover {
        opacity: .85;
      }
      img{
        width: 48px;
        height: 48px;
      }
    }
}
.poll{
  margin-block-start:0;
  label{
    margin-block-start:0;
  }
}
}

.is-layout-constrained > :where(:not(.alignleft):not(.alignright):not(.alignfull)) {
  max-width: 1200px;
  margin-left: auto !important;
  margin-right: auto !important;
}

.wp-embed-responsive .wp-has-aspect-ratio .wp-block-embed__wrapper:before {
  content: "";
  display: block;
  padding-top: 50%;
}


.wp-embed-responsive .wp-embed-aspect-16-9 .wp-block-embed__wrapper:before {
  padding-top: 56.25%;
}

.wp-embed-responsive .wp-has-aspect-ratio iframe {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .post_content{
    figure{
      width: 100% !important;
      margin-right: 0px !important;
    }
    .wp-block-image.alignleft {
      img{
        width: 100% !important;
      }
    }
  }
}
