/* ---- alice-carousel-button ---- */
  
.alice-carousel-button, .alice-carousel-prev-next-button {
    position: absolute;
    background: hsla(0, 0%, 100%, 0.75);
    border: none;
    color: #333;
  }
  
  .alice-carousel-button:hover {
    background: white;
    cursor: pointer;
  }
  
  .alice-carousel-button:focus {
    outline: none;
    box-shadow: 0 0 0 5px orange;
  }
  
  .alice-carousel-button:active {
    opacity: 0.6;
  }
  
  .alice-carousel-button:disabled {
    opacity: 0.3;
    cursor: auto;
    /* prevent disabled button from capturing pointer up event. #716 */
    pointer-events: none;
  }
  
  .alice-carousel-button-icon {
    fill: currentColor;
  }
  
  /* ---- previous/next buttons ---- */
  
  .alice-carousel-prev-next-button {
    top: 50%;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    /* vertically center */
    transform: translateY(-50%);
  }
  
  .alice-carousel-prev-next-button.previous { left: 10px; }
  .alice-carousel-prev-next-button.next { right: 10px; }
  /* right to left */
  .alice-carousel-rtl .alice-carousel-prev-next-button.previous {
    left: auto;
    right: 10px;
  }
  .alice-carousel-rtl .alice-carousel-prev-next-button.next {
    right: auto;
    left: 10px;
  }
  
  .alice-carousel-prev-next-button .alice-carousel-button-icon {
    position: absolute;
    left: 20%;
    top: 20%;
    width: 60%;
    height: 60%;
  }

  .carouselTerm ul {
    display: flex;
    gap: 2rem;
  }