$element : ("img", ".wp-block-gallery",".wp-block-buttons", ".singleSound","iframe",".wp-block-image","figure",".soundcloud-player");

@mixin stylePrintPreviusNumberArticle($element){

    @page {
        margin: 22mm 8mm;
    }
    @media print {
        html, body {
            width: 210mm;
            height:260mm;        
        }
        .articleWrap {
            border-right: 0px;
        }
        .printContent:after {
            content: '©buddismoesocieta.org - diritti riservati, riproduzione riservata';
            display: block;
            text-align: left;
            margin-top: 50px;
            color: var(--wp--preset--color--secondary);
            }
            .printContent .wp-block-group.has-background {
                padding: 20px !important;
            }
    }
    @media print {
       
        .printContent {
            @each $i in $element{
                #{$i} {
                display: none;
                }
        
                
            }
        }
        .soundcloud-player{
            display: none;
        }
        iframe {
            display: none;
        }
        
        
    }
}

@include stylePrintPreviusNumberArticle($element);