:root {
  --wp--preset--color--black: #000000;
  --wp--preset--color--cyan-bluish-gray: #abb8c3;
  --wp--preset--color--white: #ffffff;
  --wp--preset--color--pale-pink: #f78da7;
  --wp--preset--color--vivid-red: #cf2e2e;
  --wp--preset--color--luminous-vivid-orange: #ff6900;
  --wp--preset--color--luminous-vivid-amber: #fcb900;
  --wp--preset--color--light-green-cyan: #7bdcb5;
  --wp--preset--color--vivid-green-cyan: #00d084;
  --wp--preset--color--pale-cyan-blue: #8ed1fc;
  --wp--preset--color--vivid-cyan-blue: #0693e3;
  --wp--preset--color--vivid-purple: #9b51e0;
  --wp--preset--color--base: #ffffff;
  --wp--preset--color--contrast: #000000;
  --wp--preset--color--dark: #333333;
  --wp--preset--color--primary: #0ca1ba;
  --wp--preset--color--secondary: #b3b3b3;
  --wp--preset--color--tertiary: #e6e6e6;
  --wp--preset--color--magenta: #ed1e79;
  --wp--preset--color--custom-rosso:#d52d2c;
  --wp--preset--color--custom-light-blue: #DDF5F7;
  --wp--preset--color--custom-light-grey: #E5E6E7;
  --wp--preset--color--custom-arancio: #f39334;
  --wp--preset--color--custom-base: #3b444d;
  --wp--preset--color--custom-gray: #7d8189;
  --wp--preset--color--custom-teal: #009ba2;
  --wp--preset--color--custom-beige: #847770;
  --wp--preset--color--base: #f9f9f9;
  --wp--preset--color--base-2: #ffffff;
  --wp--preset--color--contrast: #111111;
  --wp--preset--color--contrast-2: #636363;
  --wp--preset--color--contrast-3: #A4A4A4;
  --wp--preset--color--accent: #cfcabe;
  --wp--preset--color--accent-2: #c2a990;
  --wp--preset--color--accent-3: #d8613c;
  --wp--preset--color--accent-4: #b1c5a4;
  --wp--preset--color--accent-5: #b5bdbc;
  --wp--preset--gradient--vivid-cyan-blue-to-vivid-purple: linear-gradient(
    135deg,
    rgba(6, 147, 227, 1) 0%,
    rgb(155, 81, 224) 100%
  );
  --wp--preset--gradient--light-green-cyan-to-vivid-green-cyan: linear-gradient(
    135deg,
    rgb(122, 220, 180) 0%,
    rgb(0, 208, 130) 100%
  );
  --wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange: linear-gradient(
    135deg,
    rgba(252, 185, 0, 1) 0%,
    rgba(255, 105, 0, 1) 100%
  );
  --wp--preset--gradient--luminous-vivid-orange-to-vivid-red: linear-gradient(
    135deg,
    rgba(255, 105, 0, 1) 0%,
    rgb(207, 46, 46) 100%
  );
  --wp--preset--gradient--very-light-gray-to-cyan-bluish-gray: linear-gradient(
    135deg,
    rgb(238, 238, 238) 0%,
    rgb(169, 184, 195) 100%
  );
  --wp--preset--gradient--cool-to-warm-spectrum: linear-gradient(
    135deg,
    rgb(74, 234, 220) 0%,
    rgb(151, 120, 209) 20%,
    rgb(207, 42, 186) 40%,
    rgb(238, 44, 130) 60%,
    rgb(251, 105, 98) 80%,
    rgb(254, 248, 76) 100%
  );
  --wp--preset--gradient--blush-light-purple: linear-gradient(
    135deg,
    rgb(255, 206, 236) 0%,
    rgb(152, 150, 240) 100%
  );
  --wp--preset--gradient--blush-bordeaux: linear-gradient(
    135deg,
    rgb(254, 205, 165) 0%,
    rgb(254, 45, 45) 50%,
    rgb(107, 0, 62) 100%
  );
  --wp--preset--gradient--luminous-dusk: linear-gradient(
    135deg,
    rgb(255, 203, 112) 0%,
    rgb(199, 81, 192) 50%,
    rgb(65, 88, 208) 100%
  );
  --wp--preset--gradient--pale-ocean: linear-gradient(
    135deg,
    rgb(255, 245, 203) 0%,
    rgb(182, 227, 212) 50%,
    rgb(51, 167, 181) 100%
  );
  --wp--preset--gradient--electric-grass: linear-gradient(
    135deg,
    rgb(202, 248, 128) 0%,
    rgb(113, 206, 126) 100%
  );
  --wp--preset--gradient--midnight: linear-gradient(
    135deg,
    rgb(2, 3, 129) 0%,
    rgb(40, 116, 252) 100%
  );
  --wp--preset--font-size--small: clamp(
    0.875rem,
    0.875rem + ((1vw - 0.2rem) * 0.204),
    1rem
  );
  --wp--preset--font-size--medium: clamp(
    1rem,
    1rem + ((1vw - 0.2rem) * 0.204),
    1.125rem
  );
  --wp--preset--font-size--large: clamp(
    1.75rem,
    1.75rem + ((1vw - 0.2rem) * 0.204),
    1.875rem
  );
  --wp--preset--font-size--x-large: 2.25rem;
  --wp--preset--font-size--xx-large: clamp(
    4rem,
    4rem + ((1vw - 0.2rem) * 9.796),
    10rem
  );
  --wp--preset--spacing--20: min(1.5rem, 2vw);
  --wp--preset--spacing--30: min(2.5rem, 3vw);
  --wp--preset--spacing--40: min(4rem, 5vw);
  --wp--preset--spacing--50: min(6.5rem, 8vw);
  --wp--preset--spacing--60: min(10.5rem, 13vw);
  --wp--preset--spacing--70: 3.38rem;
  --wp--preset--spacing--80: 5.06rem;
  --wp--preset--spacing--10: 1rem;
  --wp--preset--shadow--natural: 6px 6px 9px rgba(0, 0, 0, 0.2);
  --wp--preset--shadow--deep: 12px 12px 50px rgba(0, 0, 0, 0.4);
  --wp--preset--shadow--sharp: 6px 6px 0px rgba(0, 0, 0, 0.2);
  --wp--preset--shadow--outlined: 6px 6px 0px -3px rgba(255, 255, 255, 1),
    6px 6px rgba(0, 0, 0, 1);
  --wp--preset--shadow--crisp: 6px 6px 0px rgba(0, 0, 0, 1);
}
